import Component from "../classes/Component";
import { loadAssets } from "../utils/assets";
import { mapEach } from "../utils/dom";

export default class Thumbnails extends Component {
  constructor({ element, id }) {
    super({
      element,
      elements: {
        modals: "[data-thumbnail-modal]",
        close: ".c-modal__close",
        prev: ".c-modal__arrow--left",
        next: ".c-modal__arrow--right",
      },
    });

    this.currentModal = null;

    this.showButtonEvent();
    this.closeButtonEvent();
    this.createSlideEvents();
  }

  show(id) {
    this.currentModal = Array.from(this.elements.modals).find(
      (item) => item.id === id
    );

    if (this.currentModal) {
      this.currentModal.classList.add("active");

      loadAssets(this.currentModal, "modal-src");
      this.initSlider();
    }
  }

  hide() {
    if (this.currentModal) {
      this.currentModal.classList.remove("active");
    }
  }

  initSlider() {
    this.images = [
      ...this.currentModal.querySelectorAll(".c-modal--thumbnail__image"),
    ];

    this.indexEl = this.currentModal.querySelector(".c-modal__title span");
    this.currentIndex = Number(this.indexEl.getAttribute("data-current"));
  }

  next() {
    if (this.currentIndex === this.images.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }

    this.indexEl.setAttribute("data-current", this.currentIndex);
    this.indexEl.innerHTML = this.currentIndex + 1;

    this.images.forEach((image, index) => {
      if (index === this.currentIndex) {
        image.classList.add("active");
      } else {
        image.classList.remove("active");
      }
    });
  }

  prev() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.images.length - 1;
    } else {
      this.currentIndex -= 1;
    }

    this.indexEl.setAttribute("data-current", this.currentIndex);
    this.indexEl.innerHTML = this.currentIndex + 1;

    this.images.forEach((image, index) => {
      if (index === this.currentIndex) {
        image.classList.add("active");
      } else {
        image.classList.remove("active");
      }
    });
  }

  showButtonEvent() {
    const reelButtons = document.querySelectorAll("[data-thumbnail]");
    mapEach(reelButtons, (button) => {
      button.addEventListener("click", (e) => {
        this.show(button.getAttribute("data-thumbnail"));
      });
    });
  }

  closeButtonEvent() {
    mapEach(this.elements.close, (button) => {
      button.addEventListener("click", () => {
        this.hide();
      });
    });
  }

  createSlideEvents() {
    mapEach(this.elements.prev, (button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        this.prev();
      });
    });

    mapEach(this.elements.next, (button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        this.next();
      });
    });
  }
}
