import Page from "../../classes/Page";
import Scrolling from "../../components/Scrolling";
import { loadAssets } from "../../utils/assets";
import { delay } from "../../utils/math";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "home--active",
      },
      element: ".home",
      elements: {
        list: ".home__highlights__list",
        wrapper: ".home__wrapper",
        items: ".home__highlights__item",
      },
    });

    this.createList();
  }

  show() {
    this.element.classList.add(this.classes.active);
    this.list.onResize();

    this.bubbleVideos = this.elements.wrapper.querySelectorAll("[data-video]");

    this.bubbleVideos.forEach((element) => {
      const video = document.createElement("video");
      video.src = element.dataset.video;
      video.muted = true;
      video.loop = true;
      video.autoplay = true;
      video.playsInline = true;

      element.appendChild(video);
    });

    loadAssets(this.element);

    return super.show();
  }

  async hide() {
    this.element.classList.remove(this.classes.active);

    this.bubbleVideos.forEach((element) => {
      const video = element.querySelector("video");

      element.removeChild(video);
    });

    await delay(400);

    this.list.disable();

    return super.hide();
  }

  createList() {
    if (this.list) return;
    this.list = new Scrolling({
      element: document.body,
      elements: {
        list: this.elements.list,
        items: this.elements.items,
      },
    });
  }

  onTouchDown(event) {
    super.onTouchDown(event);
    this.list.onTouchDown(event);
  }

  onTouchMove(event) {
    super.onTouchMove(event);
    this.list.onTouchMove(event);
  }

  onTouchUp(event) {
    super.onTouchUp(event);
    this.list.onTouchUp(event);
  }

  onWheel(event) {
    super.onWheel(event);
    this.list.onWheel(event);
  }

  onResize() {
    this.list.onResize();

    window.innerWidth > 768 ? this.list.enable() : this.list.disable();

    super.onResize();
  }

  update(isTransitioning) {
    super.update(isTransitioning);

    this.list.update();
  }
}
