import Page from "../../classes/Page";
import { loadAssets } from "../../utils/assets";
import { delay } from "../../utils/math";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "cases--active",
        caseActive: "case--active",
        navActive: "case__nav--active",
        mediaActive: "case__gallery__media__placeholder--active",
      },
      element: ".cases",
      elements: {
        wrapper: "#rigged-animation",
        cases: ".case",
        navs: ".case__nav",
      },
    });
  }

  show(url) {
    this.element.classList.add(this.classes.active);
    const id = url.replace("/category/", "").replace("/", "");

    if (this.elements.cases instanceof HTMLElement) {
      this.elements.cases = [this.elements.cases];
    }

    this.elements.wrapper = Array.from(this.elements.cases).find(
      (item) => item.id === id
    );

    if (!this.elements.wrapper) {
      document.querySelector(".notfound").classList.add("notfound--active");
    } else {
      loadAssets(this.elements.wrapper);

      this.bubbleVideos =
        this.elements.wrapper.querySelectorAll("[data-video]");

      this.bubbleVideos.forEach((element) => {
        const video = document.createElement("video");
        video.src = element.dataset.video;
        video.muted = true;
        video.loop = true;
        video.autoplay = true;
        video.playsInline = true;

        element.appendChild(video);
      });

      this.elements.wrapper.classList.add(this.classes.caseActive);

      this.elements.casesNav = Array.from(this.elements.navs).find(
        (item) => item.id === id
      );
      this.elements.casesNav.classList.add(this.classes.navActive);

      this.footerPosition =
        this.elements.wrapper.getBoundingClientRect().height -
        this.elements.wrapper
          .querySelector(".case__footer")
          .getBoundingClientRect().height +
        this.elements.wrapper
          .querySelector(".c-category")
          .getBoundingClientRect().height -
        this.elements.casesNav.getBoundingClientRect().height;
    }

    return super.show();
  }

  async hide() {
    if (!this.elements.wrapper) {
      document.querySelector(".notfound").classList.remove("notfound--active");
      this.element.classList.remove(this.classes.active);
      return;
    }
    this.elements.wrapper.classList.remove(this.classes.caseActive);
    this.elements.casesNav.classList.remove(this.classes.navActive);

    this.bubbleVideos.forEach((element) => {
      const video = element.querySelector("video");

      element.removeChild(video);
    });

    this.element.classList.remove(this.classes.active);

    await delay(400);

    this.elements.wrapper = null;

    return super.hide();
  }

  onResize() {
    if (!this.elements.wrapper || this.elements.wrapper instanceof NodeList)
      return;
    super.onResize();

    this.footerPosition =
      this.elements.wrapper.getBoundingClientRect().height -
      this.elements.wrapper
        .querySelector(".case__footer")
        .getBoundingClientRect().height +
      this.elements.wrapper.querySelector(".c-category").getBoundingClientRect()
        .height -
      this.elements.casesNav.getBoundingClientRect().height;
  }

  update(isTransitioning) {
    if (!this.elements.wrapper || this.elements.wrapper instanceof NodeList)
      return;

    if (this.footerPosition <= this.scroll.target) {
      this.elements.casesNav.classList.add("no-bg");
    } else {
      this.elements.casesNav.classList.remove("no-bg");
    }
    super.update(isTransitioning);
  }
}
