import AutoBind from "auto-bind";
import each from "lodash/each";
import { Cursor } from "./animations/cursor";
import Transition from "./animations/Transition";
import Detection from "./classes/Detection";
import Preloader from "./components/Preloader";
import Thumbnails from "./components/Thumbnails";
import VideoPlay from "./components/Video";
import Brain from "./components/Brain";
import Case from "./pages/Case";
import Home from "./pages/Home";
import { mapEach } from "./utils/dom";
class App {
  constructor() {
    AutoBind(this);

    this.url = window.location.pathname;

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createPreloader();
    this.createTransition();
    this.createBrain();
    this.createCursor();
    this.createCase();
    this.createHome();
    this.createPlayers();

    this.pages = {
      "/": this.home,
      "/category": this.case,
    };

    if (this.url.indexOf("/category") > -1) {
      this.page = this.case;
      this.page.show(this.url);

      this.page.onResize();
    } else if (!Object.keys(this.pages).includes(this.url)) {
      document.querySelector(".notfound").classList.add("notfound--active");
      this.page = null;
    } else {
      this.page = this.pages[this.url];
      this.page.show(this.url);
    }

    // if(Array.values)

    // this.page.show(this.url);

    this.addEventListeners();
    this.addLinksEventsListeners();
    this.update();

    this.onResize();
  }

  createPreloader() {
    this.preloader = new Preloader();

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  createTransition() {
    this.transition = new Transition();
  }

  createCursor() {
    this.cursor = new Cursor(document.querySelector(".cursor"));
    this.cursor.checkEnter();
  }

  createHome() {
    this.home = new Home();
  }

  createCase() {
    this.case = new Case();
  }

  createPlayers() {
    this.video = new VideoPlay({
      element: "[data-yt-player]",
    });

    mapEach(document.querySelectorAll("[data-play-youtube]"), (element) => {
      element.addEventListener("click", () => {
        const id = element.getAttribute("data-play-youtube");
        const title = element.dataset.title;
        this.video.loadVideo(id, title);
      });
    });
  }

  createThumbnails() {
    this.thumbnails = new Thumbnails({ element: ".thumbnail-modals" });
  }

  createBrain() {
    const brains = document.querySelectorAll(".brain-eye");
    this.brainAnim = mapEach(brains, (element, index) => {
      return new Brain({
        element,
        index,
      });
    });
  }

  onPreloaded() {
    this.onResize();
    this.createThumbnails();
  }

  async onChange({ push = true, url = null }) {
    url = url.replace(window.location.origin, "");

    if (this.isFetching || this.url === url) return;

    this.isFetching = true;

    this.url = url;

    if (this.canvas) {
      this.canvas.onChange(this.url);
    }

    await this.transition.show();

    if (this.video) {
      this.video.hideVideo();
    }

    if (this.page) {
      await this.page.hide();
    }

    if (push) {
      window.history.pushState({}, document.title, url);
    }

    if (this.url.indexOf("/category") > -1) {
      this.page = this.case;
    } else {
      document.querySelector(".notfound").classList.remove("notfound--active");
      this.page = this.pages[this.url];
    }

    await this.page.show(this.url);

    this.transition.hide();

    this.isFetching = false;
  }

  /**
   * Events
   */

  onResize() {
    if (this.home) {
      this.home.onResize();
    }

    if (this.case) {
      this.case.onResize();
    }

    each(this.brainAnim, (brain) => {
      brain.onResize();
    });
  }

  onWheel(event) {
    if (this.page && this.page.onWheel) {
      this.page.onWheel(event);
    }
  }

  onPopState() {
    this.onChange({
      url: window.location.pathname,
      push: false,
    });
  }

  onTouchDown(event) {
    event.stopPropagation();

    if (!Detection.isMobile() && event.target.tagName === "A") return;

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(event);
    }
  }

  onTouchMove(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchMove) {
      this.page.onTouchMove(event);
    }

    each(this.brainAnim, (brain) => {
      brain.updateTrans(event);
    });
  }

  onTouchUp(event) {
    event.stopPropagation();

    this.mouse.x = event.changedTouches
      ? event.changedTouches[0].clientX
      : event.clientX;
    this.mouse.y = event.changedTouches
      ? event.changedTouches[0].clientY
      : event.clientY;

    if (this.page && this.page.onTouchUp) {
      this.page.onTouchUp(event);
    }
  }

  // onLoad() {}

  /**
   * Loop
   */
  update() {
    if (this.page) {
      this.page.update(this.transition.isTransitioning);
    }

    if (this.cursor) {
      this.cursor.update();
    }

    window.requestAnimationFrame(this.update);
  }

  /**
   * Listeners
   */
  addLinksEventsListeners() {
    const links = document.querySelectorAll("a");

    each(links, (link) => {
      const isLocal = link.href.indexOf(window.location.origin) > -1;

      if (isLocal) {
        link.onclick = (event) => {
          event.preventDefault();

          this.onChange({
            url: link.href,
          });
        };
      } else if (
        link.href.indexOf("mailto") === -1 &&
        link.href.indexOf("tel") === -1
      ) {
        link.rel = "noopener";
        link.target = "_blank";
      }
    });
  }

  addEventListeners() {
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("popstate", this.onPopState, { passive: true });

    window.addEventListener("mousewheel", this.onWheel, { passive: true });
    window.addEventListener("wheel", this.onWheel, { passive: true });

    window.addEventListener("mousedown", this.onTouchDown, { passive: true });
    window.addEventListener("mousemove", this.onTouchMove, { passive: true });
    window.addEventListener("mouseup", this.onTouchUp, { passive: true });

    window.addEventListener("touchstart", this.onTouchDown, { passive: true });
    window.addEventListener("touchmove", this.onTouchMove, { passive: true });
    window.addEventListener("touchend", this.onTouchUp, { passive: true });
  }
}

new App();
