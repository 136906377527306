import gsap from "gsap";
import Animation from "../classes/Animation";

export default class extends Animation {
  constructor({ element }) {
    super({
      element,
    });

    this.onResize();

    if ("IntersectionObserver" in window) {
      this.animateOut();
    }
  }

  animateIn() {
    if (this.hasAnimatedIn) return;
    super.animateIn();

    gsap.to(this.element, {
      duration: 1,
      delay: 0.2,
      autoAlpha: 1,
    });
    this.hasAnimatedIn = true;
  }

  animateOut() {
    if (this.hasAnimatedIn) return;

    this.element.style.opacity = 0;
    super.animateOut();
  }

  onResize() {}
}
