import AutoBind from "auto-bind";
import Prefix from "prefix";
import NormalizeWheel from "normalize-wheel";
import each from "lodash/each";
import { clamp, lerp } from "../utils/math";
import Magnetic from "../animations/Magnetic";
import { mapEach } from "../utils/dom";
// import Paragraph from "../animations/Paragraph";
import FadeIn from "../animations/FadeIn";
import gsap from "gsap";
import InlineVideos from "../components/InlineVideos";

export default class Page {
  constructor({ classes, element, elements }) {
    AutoBind(this);

    this.classes = {
      ...classes,
    };

    this.selector = element;
    this.selectorChildren = {
      ...elements,
      animationsMagnetics: '[data-animation="magnetic"]',
      animationsParagraphs: '[data-animation="paragraph"]',
      animationsFadeIn: '[data-animation="fadein"]',
      youtubePlayer: "[data-play-youtube]",
      gotoButton: "[data-goto]",
    };

    this.create();

    this.scroll = {
      ease: window.innerWidth > 768 ? 0.1 : 0.15,
      position: 0,
      current: 0,
      target: 0,
      limit: 0,
    };

    this.transformPrefix = Prefix("transform");
    this.createAnimations();
    // this.createPlayers();
    this.createGoto();
    this.brain = document.querySelector(".brain");
  }

  create() {
    this.animations = [];
    if (this.selector instanceof HTMLElement) {
      this.element = this.selector;
    } else {
      this.element = document.querySelector(this.selector);
    }

    this.elements = {};

    Object.keys(this.selectorChildren).forEach((key) => {
      const entry = this.selectorChildren[key];

      if (
        entry instanceof HTMLElement ||
        entry instanceof NodeList ||
        Array.isArray(entry)
      ) {
        this.elements[key] = entry;
      } else {
        this.elements[key] = this.element.querySelectorAll(entry);

        if (this.elements[key].length === 0) {
          this.elements[key] = null;
        } else if (this.elements[key].length === 1) {
          this.elements[key] = this.element.querySelector(entry);
        }
      }
    });
  }

  transform(element, y) {
    element.style[this.transformPrefix] = `translate3d(0, ${-Math.round(
      y
    )}px, 0)`;
  }

  show() {
    this.scroll.position = 0;
    this.scroll.current = 0;
    this.scroll.target = 0;
    this.onResize();

    this.inlineVideos = new InlineVideos();

    return Promise.resolve();
  }

  hide() {
    this.inlineVideos.unmount();

    return Promise.resolve();
  }

  createAnimations() {
    this.animationsMagnetics = mapEach(
      this.elements.animationsMagnetics,
      (element, index) => {
        return new Magnetic({
          element,
        });
      }
    );
    this.animations.push(...this.animationsMagnetics);

    // this.animationsParagraphs = mapEach(
    //   this.elements.animationsParagraphs,
    //   (element) => {
    //     return new Paragraph({ element });
    //   }
    // );

    // this.animations.push(...this.animationsParagraphs);

    this.animationsFadeIn = mapEach(
      this.elements.animationsFadeIn,
      (element) => {
        return new FadeIn({ element });
      }
    );

    this.animations.push(...this.animationsFadeIn);
  }

  createGoto() {
    mapEach(this.elements.gotoButton, (element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        const target = event.currentTarget.getAttribute("data-goto");
        const targetElement = document.getElementById(target);

        if (targetElement) {
          // ease scroll to
          this.scroll.target = targetElement.getBoundingClientRect().top;
        } else {
          this.scroll.target = this.scroll.limit;
        }
      });
    });
  }

  /**
   * Events
   */

  onResize() {
    this.scroll.limit = this.elements.wrapper.clientHeight - window.innerHeight;

    each(this.animations, (animation) => {
      animation.onResize && animation.onResize();
    });
  }

  onWheel(event) {
    const normalized = NormalizeWheel(event);
    const speed = normalized.pixelY;

    this.scroll.target += speed;
  }

  onTouchDown(event) {
    this.isDown = true;

    this.scroll.position = this.scroll.current;
    this.start = event.touches ? event.touches[0].clientY : 0;
  }

  onTouchMove(event) {
    if (!this.isDown) return;

    const y = event.touches ? event.touches[0].clientY : 0;
    const distance = (this.start - y) * 2;

    this.scroll.target = this.scroll.position + distance;
  }

  onTouchUp(event) {
    this.isDown = false;
  }

  /**
   * Update
   */

  update(isTransitioning) {
    this.scroll.target = clamp(0, this.scroll.limit, this.scroll.target);

    this.scroll.current = lerp(
      this.scroll.current,
      this.scroll.target,
      this.scroll.ease
    );

    this.brainPosition = gsap.utils.mapRange(
      0,
      this.scroll.limit,
      0,
      80,
      this.scroll.current
    );

    if (!isTransitioning) {
      gsap.to(this.brain, {
        duration: 2,
        y: -this.brainPosition,
        x: 0,
        ease: "power2.out",
      });
    }

    if (this.scroll.current < 0.1) {
      this.scroll.current = 0;
    }

    if (this.elements.wrapper) {
      this.transform(this.elements.wrapper, this.scroll.current);
    }

    this.animationsMagnetics.forEach((animation) => {
      animation.updatePosition();
    });
  }
}
