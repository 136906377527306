export default class InlineVideos {
  constructor() {
    this.videos = document.querySelectorAll("[playsinline]");

    this.initVideos();
  }

  initVideos() {
    this.videos.forEach((video) => {
      // We can only control playback without insteraction if video is mute
      video.muted = true;
      // Play is a promise so we need to check we have it
      let playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {
          this.observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio !== 1 && !video.paused) {
                  video.pause();
                } else if (video.paused) {
                  video.play();
                }
              });
            },
            { threshold: 0.2 }
          );
          this.observer.observe(video);
        });
      }
    });
  }

  unmount() {
    this.observer.disconnect();
  }
}
